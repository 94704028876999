type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export type SessionAttributes = {
  visitorId: string
  stylePreference: 'women' | 'men' | undefined
  customerType: 'firstTime' | 'repeat'
  sessionId: string
}

export const sessionStorageKeys = {
  landingPage: '@figs:landing-page',
  deprecatedFeatureFlags: '@figs:features',
  usedLocales: '@figs:used-locales',
  discount: '@figs:discount',
} as const

let sessionCounter = 0
export const createNewSessionId = () => {
  sessionCounter = sessionCounter + 1
  if (typeof window === 'undefined') {
    return `web-server-${sessionCounter}-${crypto.randomUUID()}`
  } else {
    return `web-client-${crypto.randomUUID()}`
  }
}

export const convertCookiesToSessionAttributes = ({
  stylePreferenceCookie,
  initialSessionCookie,
  priorPageViewCookie,
  sessionIdCookie,
  visitorIdCookie,
}: {
  stylePreferenceCookie: string | undefined
  initialSessionCookie: string | undefined
  priorPageViewCookie: string | undefined
  sessionIdCookie: string | undefined
  visitorIdCookie: string | undefined
}): PartialBy<SessionAttributes, 'sessionId' | 'visitorId'> => {
  return {
    stylePreference: stylePreferenceCookie as 'men' | 'women' | undefined,
    customerType:
      initialSessionCookie === 'true' || priorPageViewCookie === undefined ? 'firstTime' : 'repeat',
    sessionId: sessionIdCookie,
    visitorId: visitorIdCookie,
  }
}
