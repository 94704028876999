'use client'

import {
  Dialog,
  DialogDisclosure,
  DialogDismiss,
  DialogProvider,
  useDialogContext,
} from '@ariakit/react'
import { usePathname } from 'next/navigation'
import { ComponentProps, useRef } from 'react'
import styled, { ThemeProvider } from 'styled-components'

import { TypeStyleHeadlineExtraSmall } from '@syconium/little-miss-figgy'
import { IconButton } from '@syconium/little-miss-figgy/dist/components/IconButton/IconButton'
import { SelectMark } from '@syconium/little-miss-figgy/dist/components/Icons/Icon/SelectMark'
import { ScreenReaderOnly } from '@syconium/little-miss-figgy/dist/components/ScreenReaderOnly'
import { fromMd, timingFaster } from '@syconium/little-miss-figgy/dist/constants'
import { defaultLightTheme } from '@syconium/little-miss-figgy/dist/themes/default/default'

export const NextDialogProvider = DialogProvider

export const useNextDialogContext = useDialogContext

export const NextDialogBackdrop = styled.div`
  position: fixed;
  inset: 0px;
  z-index: ${o => o.theme.zIndex.modals};
  overflow: auto;
  padding-top: 4rem;
  padding-bottom: 4rem;
  justify-content: center;
  align-items: center;
  overscroll-behavior: contain;

  display: flex;
  &[hidden] {
    display: none;
  }

  backdrop-filter: blur(0px);
  transition:
    background-color ${timingFaster} ease,
    backdrop-filter ${timingFaster} ease;
  background-color: rgba(0, 0, 0, 0.3);
  &[data-enter] {
    backdrop-filter: blur(1px);
    background-color: rgba(0, 0, 0, 0.6);
  }
`

const StyledNextDialogHeader = styled.header`
  padding: ${o => o.theme.spacing(8, 6, 1, 6)};
  border-radius: ${o => o.theme.spacing(2, 2, 0, 0)};
`

const StyledNextDialogHeaderContentWrapper = styled.div`
  margin: 0px 42px;
  display: flex;
  flex-direction: column;
  gap: ${o => o.theme.spacing(5)};
`

type NextDialogDismissProps = ComponentProps<typeof IconButton> & {
  closeText: string
}

export const NextDialogDismiss = ({
  forwardedAs = DialogDismiss,
  closeText,
  variant = 'black-on-white-no-border',
  flushTop = true,
  flushBottom = true,
  flushRight = true,
  flushLeft = true,
  ...props
}: NextDialogDismissProps) => {
  return (
    <IconButton
      forwardedAs={forwardedAs}
      variant={variant}
      flushRight={flushRight}
      flushTop={flushTop}
      flushBottom={flushBottom}
      flushLeft={flushLeft}
      {...props}
    >
      <ScreenReaderOnly>{closeText}</ScreenReaderOnly>
      <SelectMark aria-hidden='true' />
    </IconButton>
  )
}

type NextDialogHeaderProps = ComponentProps<typeof StyledNextDialogHeader> & {
  closeText: string
}

export const NextDialogHeader = ({ children, closeText, ...props }: NextDialogHeaderProps) => {
  return (
    <StyledNextDialogHeader {...props}>
      <StyledDialogDismissWrapper>
        <NextDialogDismiss closeText={closeText} />
      </StyledDialogDismissWrapper>
      <StyledNextDialogHeaderContentWrapper>{children}</StyledNextDialogHeaderContentWrapper>
    </StyledNextDialogHeader>
  )
}

export const NextDialogBody = styled.div`
  padding: 24px;
  box-sizing: border-box;
`

const StyledDialogDismissWrapper = styled.div`
  text-align: right;
  height: 0px;
`

export const NextDialogFooter = styled.footer`
  padding: ${o => o.theme.dimensions.layout.sidePadding.md}px 24px 24px 24px;
  background-color: ${o => o.theme.color.fill.subtle};
  color: ${o => o.theme.color.text.primary.on.subtle};
  display: flex;
  flex-direction: column;
  gap: ${o => o.theme.spacing(4)};
  border-radius: ${o => o.theme.spacing(0, 0, 2, 2)};
`

const StyledDialog = styled(Dialog)`
  background-color: ${o => o.theme.color.fill.background};
  color: ${o => o.theme.color.text.primary.on.background};
  border-radius: ${o => o.theme.spacing(2)};
  margin: auto;
  height: fit-content;
  max-height: none;
  position: relative;
  inset: auto;
  text-align: center;
  justify-content: center;
  z-index: ${o => o.theme.zIndex.modals};

  max-width: calc(
    100vw - ${o => o.theme.dimensions.layout.sidePadding.md}px -
      ${o => o.theme.dimensions.layout.sidePadding.md}px
  );
  min-width: 70vw;

  max-width: calc(100% - ${o => o.theme.dimensions.layout.sidePadding.md * 2}px);

  ${fromMd} {
    max-width: 50vw;
    min-width: 420px;
  }

  transition:
    transform ${timingFaster} ease,
    opacity ${timingFaster} ease;
  opacity: 0;
  transform: translateY(50px) scale(0.98);
  &[data-enter] {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
`

type NextDialogProps = Omit<ComponentProps<typeof StyledDialog>, 'render' | 'backdrop'> & {
  backdrop?: (props: { hidden: boolean; children?: React.ReactNode }) => React.ReactNode
  nestDialogInBackdrop?: boolean
}

export const NextDialog = ({
  backdrop = NextDialogBackdrop,
  nestDialogInBackdrop = true,
  unmountOnHide = true,
  ...props
}: NextDialogProps) => {
  const Backdrop = backdrop
  const dialogContext = useDialogContext()
  const pathname = usePathname()
  const previousPathnameRef = useRef(pathname)
  if (previousPathnameRef.current !== pathname) {
    previousPathnameRef.current = pathname
    dialogContext?.hide()
  }
  return (
    <ThemeProvider theme={defaultLightTheme}>
      <StyledDialog
        unmountOnHide={unmountOnHide}
        {...props}
        render={htmlProps => {
          return (
            <>
              <Backdrop
                hidden={htmlProps.hidden ?? false}
                // @ts-ignore
                data-enter={htmlProps['data-enter'] ?? undefined}
                // @ts-ignore
                data-open={htmlProps['data-open'] ?? undefined}
              >
                {nestDialogInBackdrop ? <div {...htmlProps} /> : null}
              </Backdrop>
              {!nestDialogInBackdrop ? <div {...htmlProps} /> : null}
            </>
          )
        }}
      />
    </ThemeProvider>
  )
}

const StyledDialogTitle = styled(TypeStyleHeadlineExtraSmall)`
  font-size: 20px;
  text-align: center;
  text-wrap: balance;
  text-transform: uppercase;

  ${fromMd} {
    font-size: 18px;
  }
`

type NextDialogTitleProps = {
  children?: React.ReactNode
  className?: string
}

export const NextDialogTitle = ({ children, className }: NextDialogTitleProps) => {
  return (
    <StyledDialogTitle asTag='h2' isUppercase={true} className={className}>
      {children}
    </StyledDialogTitle>
  )
}

type NextDialogTriggerProps = ComponentProps<typeof DialogDisclosure> & {
  render: NonNullable<ComponentProps<typeof DialogDisclosure>['render']>
}

export const NextDialogTrigger = (props: NextDialogTriggerProps) => {
  return <DialogDisclosure {...props} />
}
